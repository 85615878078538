import SettlementMainScreen from './SettlementMainScreen/SettlementMainScreen.vue';
import SettlementQuotationScreen from './SettlementQuotationScreen/SettlementQuotationScreen.vue';
import EstimateParticularService from '../../../services/API/estimate_particular.service';
import ProjectService from '../../../services/API/project.service';
import TransferService from '@/services/API/transfer.service';
import InvoiceService from '@/services/API/invoice.service';
// import AddCostDetailService from '../../../services/API/add_cost_details.service';

import SettlementConst from '@/constants/SettlementConst';

import { mapState } from 'vuex';
import moment from 'moment';

export default {
	name: 'SettlementScreen',
	components: {
		SettlementMainScreen,
		SettlementQuotationScreen,
	},
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			quotationError: false,
			projectInfo: {
				projectId: this.$store.state.projectId,
				projectName: '',
				companyName: '',
			},
			isQuotationShow: false,
			estimateParticularId: undefined,
			originEstimateData: {
				estimateParticular: {},
				estimateDetails: [],
			},
			pdfData: {},
			pdfFileKey: `${this.$store.state.projectId}/pdf/template-settlement.pdf`,
			defaultFilePath: '/pdf/template.jpeg',
			expiration: '600',
			pdfFileUrl: '',
			invoiceData: {},
			showContent: false,
			settlementStatus: '',
			// addCostDetails: [],
		};
	},
	computed: {
		// Get data from store
		...mapState(['userId', 'managementMasterInfo', 'creatorInfo']),
		projectInfoStore() {
			return this.$store.state['projectInfo'];
		},
	},

	watch: {
		// Calculate amount when consumption_tax_rate and addcostDetails existed
		// managementMasterInfo(newVal) {
		// 	if (newVal['consumption_tax_rate'] && this.addCostDetails.length > 0) {
		// 		this.computeAddCostDetailsMoneyAmount(
		// 			newVal['consumption_tax_rate'],
		// 			this.addCostDetails
		// 		);
		// 	}
		// },
	},

	methods: {
		onEditButtonClick(data) {
			this.isQuotationShow = data;
		},

		onGetQuotationError() {
			this.quotationError = !this.quotationError;
		},

		onChangeStatus(status) {
			this.settlementStatus = status;
		},
		onCloseSettlementScreen() {
			this.$emit('on-close-settlement-screen');
		},
		/**
		 * Calculate subtotal of all addCostDetail records
		 * @param {Array} addCostDetails AddCostDetail array
		 * @returns addCostDetailsSubtotal
		 */
		// addCostDetailsSubtotal(addCostDetails) {
		// 	if (addCostDetails && addCostDetails.length > 0) {
		// 		return addCostDetails.reduce((acc, addCostDetail) => {
		// 			return (
		// 				acc + Math.trunc(addCostDetail['quantity'] * addCostDetail['unit_price'])
		// 			);
		// 		}, 0);
		// 	}
		// },

		/**
		 * Calculate Addcost Details money amount
		 * @param {Integer} consumptionTaxRate ManagementMaster consumption tax rate
		 * @param {Array } addCostDetails AddCostDetail array
		 */
		// computeAddCostDetailsMoneyAmount(consumptionTaxRate, addCostDetails) {
		// 	if (consumptionTaxRate && addCostDetails.length > 0) {
		// 		let addCostDetailsSubtotal = this.addCostDetailsSubtotal(addCostDetails);

		// 		let addCostDetailsConsumptionTax =
		// 			(addCostDetailsSubtotal * consumptionTaxRate) / 100;
		// 		let subtotal =
		// 			this.originEstimateData.estimateParticular['subtotal'] +
		// 			addCostDetailsSubtotal;
		// 		let consumptionTax = Math.trunc(
		// 			this.originEstimateData.estimateParticular['consumption_tax'] +
		// 				addCostDetailsConsumptionTax
		// 		);
		// 		this.originEstimateData.estimateParticular['subtotal'] = subtotal;
		// 		let holdingTax = 0;
		// 		if (this.projectInfoStore.with_holding_tax == 1) {
		// 			if (subtotal < 1000000) {
		// 				holdingTax = (subtotal * 10.21) / 100;
		// 			} else {
		// 				holdingTax = ((subtotal - 1000000) * 20.42) / 100 + 102100;
		// 			}
		// 		}
		// 		holdingTax = Math.trunc(holdingTax);
		// 		this.originEstimateData.estimateParticular['holding_tax'] = holdingTax;
		// 		this.originEstimateData.estimateParticular['subtotal'] = subtotal;
		// 		this.originEstimateData.estimateParticular['consumption_tax'] = consumptionTax;
		// 		this.originEstimateData.estimateParticular['total'] =
		// 			subtotal + consumptionTax + holdingTax;
		// 	}
		// },

		async onUploadedFile(key) {
			if (!this.estimateParticularId) return;
			this.pdfFileKey = key.pdfFileKey;
			let params = {
				pdf_file_key: this.pdfFileKey,
			};
			this.invoiceData = { ...this.invoiceData, ...params };
			// change to update invoiceData
			if (this.invoiceData['id']) {
				await this._updateInvoiceData(this.estimateParticularId, params);
			}
			await this._getPdfFileUrl(this.pdfFileKey);
		},

		async onSaveButtonClick(data) {
			this.isQuotationShow = data.isShowQuotation;
			await this._getEstimateData(this.projectInfo.projectId);
			let pdfData = {
				estimateParticular: {
					...this.originEstimateData.estimateParticular,
				},
				estimateDetails: [...this.originEstimateData.estimateDetails],
			};
			this.pdfData = pdfData;

			// Start render PDF file
			this.$refs.settlementMainScreen._renderTempateString();
		},

		onGetConfirmInfo(data) {
			// console.log(data);
			this.$emit('on-send-confirm-info', data);
		},

		/**
		 * Get Estimate Particular And Children records
		 * @param {integer} projectId : project id
		 */
		async _getEstimateParticularAndChildren(projectId) {
			try {
				let response = await EstimateParticularService.get(projectId);

				if (response && response.status === 200) {
					console.log('Get estimate particular successfully');
					let data = response.data;
					this.estimateParticularId = data['estimate_particular']['id'];
					let estimateDetails = data['estimate_details'].sort((previous, current) => {
						if (previous.id < current.id) return -1;
						if (previous.id > current.id) return 1;
						return 0;
					});
					// let addCostDetails = await this._getAddCostDetails(this.estimateParticularId);
					// this.addCostDetails = addCostDetails;
					let projectName = {
						project_name: this.projectInfo.projectName,
					};

					let bankInfo = {
						bank_name: this.creatorInfo['bank_name'],
						bank_branch: this.creatorInfo['branch_name'],
						account_type: this.creatorInfo['account_type'],
						account_holder: this.creatorInfo['account_holder'],
						account_number: this.creatorInfo['account_number'],
					};

					this.originEstimateData.estimateParticular = {
						...data['estimate_particular'],
						...projectName,
						...bankInfo,
						...{ issue_date: '' },
						...{ expiry_date: '' },
					};

					// if (addCostDetails && addCostDetails.length > 0) {
					// 	this.computeAddCostDetailsMoneyAmount(
					// 		this.managementMasterInfo['consumption_tax_rate'],
					// 		this.addCostDetails
					// 	);
					// 	this.originEstimateData.estimateDetails =
					// 		estimateDetails.concat(addCostDetails);
					// } else {
					// 	this.originEstimateData.estimateDetails = estimateDetails;
					// }
					this.originEstimateData.estimateDetails = estimateDetails;
					this.projectInfo.companyName = data['estimate_particular']['company_name'];
				} else {
					console.log('Get estimate particular failed');
				}
			} catch (error) {
				console.log(`Get estimate particular failed: ${error}`);
			}
		},

		// async _getAddCostDetails(quoteId) {
		// 	try {
		// 		let response = await AddCostDetailService.get(quoteId);
		// 		if (response && response.status !== 200) {
		// 			throw 'Get add cost detail failed';
		// 		}
		// 		let addCostDetails = response.data;
		// 		return addCostDetails;
		// 	} catch (error) {
		// 		console.log(`Get add_cost detail failed: ${error}`);
		// 	}
		// },

		/**
		 * Get project name
		 * @param {integer} projectId : project id
		 */
		async _getProjectName(projectId) {
			try {
				let response = await ProjectService.get(projectId);

				if (response && response.status === 200) {
					console.log('Get project successfully');
					let data = response.data;
					this.projectInfo.projectName = data['project_name'];
				} else {
					console.log('Get project failed');
				}
			} catch (error) {
				console.log(`Get project failed: ${error}`);
			}
		},

		async _getPreviewPDFUrl(key, expiration) {
			try {
				let response = await TransferService.get(key, expiration);
				if (!response || response.status !== 200) {
					throw 'Get PDF url failed!';
				}

				console.log('%c Get PDF url successfully!', 'color: red');
				let url = response.data.link;
				return url;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get estimate data
		 * Include: estimateParticular, EstimateDetails and projectName
		 */
		async _getEstimateData() {
			// Check if current project id existed
			if (this.projectInfo.projectId) {
				await this._getProjectName(this.projectInfo.projectId);
				await this._getEstimateParticularAndChildren(this.projectInfo.projectId);
				await this._getInvoice(this.estimateParticularId);

				if (this.invoiceData['transfers_deadline']) {
					this.isQuotationShow = true;
				}
				this.showContent = true;

				// Update issue_date, expiry_date
				this.originEstimateData.estimateParticular['issue_date'] =
					this.invoiceData['issue_date'];
				this.originEstimateData.estimateParticular['expiry_date'] =
					this.invoiceData['transfers_deadline'];
				this.originEstimateData.estimateParticular['bank_name'] =
					this.invoiceData['bank_name'];
				this.originEstimateData.estimateParticular['bank_branch'] =
					this.invoiceData['bank_branch'];
				this.originEstimateData.estimateParticular['account_type'] = this.invoiceData[
					'account_type'
				]
					? this.invoiceData['account_type']
					: SettlementConst.DEFAULT_ACCOUNT_TYPE;
				this.originEstimateData.estimateParticular['account_holder'] =
					this.invoiceData['account_holder'];
				this.originEstimateData.estimateParticular['account_number'] =
					this.invoiceData['account_number'];
				this.pdfData = { ...this.originEstimateData };
				await this._getPdfFileUrl(this.pdfFileKey);
			}
		},

		async _getPdfFileUrl(pdfFileKey) {
			if (pdfFileKey) {
				this.pdfFileUrl = await this._getPreviewPDFUrl(pdfFileKey, this.expiration);
			}
		},

		async _updateEstimateParticular(estimateParticularId, body) {
			let response = await EstimateParticularService.update(estimateParticularId, {
				doc_content: body,
			});
			if (response && response.status === 200) {
				console.log('Update Estimate particular successfully!');
			} else {
				throw response.message;
			}
		},

		/**
		 * Get Invoice Data
		 * @param {Integer} quoteId EstimateParticular ID
		 * @returns invoiceData
		 */
		async _getInvoice(quoteId) {
			try {
				let response = await InvoiceService.get(quoteId);
				if (!response || response.status !== 200) {
					throw 'Get Invoice failed!';
				}

				console.log('%c Get Invoice successfully!', 'color: red');

				let invoiceData = response.data;
				if (!invoiceData['issue_date']) {
					let nowDate = moment(Date.now()).format('YYYY-MM-DD');
					invoiceData['issue_date'] = nowDate;
				}
				this.invoiceData = { ...invoiceData };
				this.settlementStatus = invoiceData['status'];
				this.pdfFileKey = invoiceData['pdf_file_key'];
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 *  Update Invoice
		 * @param {Integer} quoteId EstimateParticular ID
		 * @param {Object} body update content
		 */
		async _updateInvoiceData(quoteId, body) {
			try {
				let response = await InvoiceService.update(quoteId, body);
				if (!response || response.status !== 200) {
					throw 'Update Invoice failed!';
				}

				console.log('%c Update Invoice successfully!', 'color: red');
			} catch (error) {
				console.log(error);
			}
		},
	},
	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		await this._getEstimateData();

		this.$store.commit('setIsAppProcessing', false);
	},
};
