import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import NotificationModal from '../../../../components/NotificationModal/NotificationModal.vue';
import socketClient from '../../../../services/SOCKET';
import HxPdfExporter from '../../../../components/HxPdfExporter/HxPdfExporter.vue';
import EstimateParticularService from '../../../../services/API/estimate_particular.service';
import Modal from '../../../../components/Modal/Modal.vue';
import TransferService from '@/services/API/transfer.service';
import StatusBadge from '@/components/Badge/StatusBadge.vue';
import InvoiceService from '@/services/API/invoice.service';
import DocumentService from '@/services/API/document.service';
// import ProjectService from '@/services/API/project.service';

import { sendNotify, toLineMessageTemplate } from '@/utilities';
import { checkMobileScreen } from '@/store/modules/ScreenWidth';
import emitSoundMixin from '@/mixin/emitSound';

import moment from 'moment';
import Axios from 'axios';
import { mapState } from 'vuex';

const SELECTIVE_NOTI_CONTENTS = checkMobileScreen()
	? ' 「 御請求書 」 をクライアントに\n送信します'
	: ' 「 御請求書 」 をクライアントに送信します';

const ERROR_CONTENTS = '転送期限を追加してください。';

export default {
	components: {
		ScreenHeader,
		NotificationModal,
		HxPdfExporter,
		BottomButton,
		Modal,
		StatusBadge,
	},
	mixins: [emitSoundMixin],
	props: {
		pdfData: {
			type: Object,
		},
		quotationError: {
			type: Boolean,
		},
		pdfFileUrl: {
			type: String,
		},
		invoiceData: {
			type: Object,
		},
		settlementStatus: {
			type: String,
		},
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			middleContent: ' 様 ／ ',
			screenName: '請求書作成',
			selectiveContents: SELECTIVE_NOTI_CONTENTS,
			errorContents: '',
			companyAddress: '',
			total: '',
			issueDate: '',
			postNumber: '',
			showButton: false,
			showDownloadButton: false,
			isInvoiceSaved: false,
			defaultFileName: '',
			pdf: '',
			isSaveButtonClicked: false,
			pdfFileData: '',
			selectiveModalId: 'settlement-selective-modal',
			buttonName: '送 信',
			showEditButton: false,
			directLink: 'finish',
			mailContent: '',
			isSearchIconShow: false,
			pdfFileKey: '',
			quoteId: undefined,
			pdfPreviewer: {},
			mailTitle: '',
			subject: '',
			isSendCreator: false,
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'appLogo',
			'appLogoPlain',
		]),
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}`
						: `${this.projectInfo['process_client_url']}`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}`
					: `${this.projectInfo['process_client_url']}`,
			});
		},

		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},
	watch: {
		projectInfo: {
			handler: function (projectInfo, clientInfo) {
				this._getDefaultFileName(projectInfo, clientInfo);
			},
			deep: true,
		},

		pdfData: {
			handler: function (pdfData) {
				this.isSaveButtonClicked = true;
				this.projectName = pdfData.estimateParticular['project_name'];
			},
			deep: true,
		},

		invoiceData: {
			handler: function (invoiceData) {
				if (invoiceData['transfers_deadline']) this.isInvoiceSaved = true;
				this._getEstimateParticular(this.projectId);
			},
		},
	},
	methods: {
		onClose() {
			this.$emit('on-close-settlement-screen');
		},
		async onDownload() {
			this.$store.commit('setIsAppProcessing', true);

			if (!this.pdfFileUrl) {
				this.$store.commit('setIsAppProcessing', false);
				return;
			}

			this._getDefaultFileName(this.projectInfo, this.clientInfo);
			// Using Axios get PDF file to browser and rename it before downloading
			// Reason: Can not rename PDF file directly by external URL just using <a> tag
			try {
				let pdfFile = await Axios.get(this.pdfFileUrl, {
					headers: {
						'Content-Type': 'application/octet-stream',
					},
					responseType: 'blob',
				});
				let aTag = document.createElement('a');
				let url = window.URL.createObjectURL(pdfFile.data);
				aTag.href = url;
				aTag.download = this.defaultFileName;
				aTag.click();
			} catch (error) {
				console.log(error);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		onEditButtonClick() {
			this.$emit('on-edit-btn-click', false);
		},

		onBottomButtonClick() {
			if (this.isInvoiceSaved) {
				this.$bvModal.show(`${this.selectiveModalId}`);
			} else {
				this.errorContents = ERROR_CONTENTS;
				this.$bvModal.show('settlement-main-error-modal');
			}
			return;
		},

		async onRenderTemplate() {
			let pdfFileData = await this.$refs.hxPdfExporter.getRenderedPDFTemplate(
				'invoice.pdf'
			);
			let pdfFileParams = {
				content: pdfFileData,
				project_id: this.projectId,
				file_name: 'template-settlement.pdf',
			};
			let pdfFileKey = await this._uploadPdf(pdfFileParams);
			this.pdfFileKey = pdfFileKey;
			this.$emit('on-transfer-uploaded-file', { pdfFileKey });

			this.$store.commit('setIsAppProcessing', false);
		},

		_renderTempateString() {
			this.$refs.hxPdfExporter._renderTemplateString();
		},

		async onSendConfirmRequire() {
			this.$store.commit('setIsAppProcessing', true);

			this.emitMixinProcessingSound({ isSocketIncluded: false });

			await this.$emit('on-change-settlement-status', 'onWaitingPayment');

			// // Update client_finished of project and direct to finish screen
			// await this._updateProject(this.projectId, {
			// 	creator_finished: 1,
			// });
			// let projectInfo = { ...this.projectInfo, creator_finished: 1 };
			// this.$store.commit('setProjectInfo', projectInfo);

			await this._updateInvoice(this.quoteId, {
				status: 'onWaitingPayment',
				receipt_data: this.pdfData,
			});
			await this._updateEstimateParticular(this.quoteId, {
				pdf_invoice_file_key: this.pdfFileKey,
			});

			this._checkStatus();

			socketClient.send('settlement_quotation_content', {
				userId: this.userId,
				projectId: this.projectId,
				content: 'onWaitingPayment',
			});
			let transfersDeadline = moment(this.estimateParticular['expiry_date']).format(
				'YYYY年MM月DD日'
			);
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］請求書を送信しました`;
			this.mailContent = `請求書を送信しました。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 請求書を送信しました`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 請求書が届きました`;
			this.mailContent = `請求書が届きました。\n支払期限は、${transfersDeadline}です。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 請求書が届きました`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);
			this.$store.commit('setIsAppProcessing', false);
		},

		onPDFClick() {
			this.pdfPreviewer.url = this.pdfFileUrl;
			this.$bvModal.show(`preview-pdf-modal`);
		},

		_disableElements() {
			this.showButton = false;
		},

		_getDefaultFileName(projectInfo, clientInfo) {
			let nowDate = new Date();
			let downloadDateTime = moment(nowDate).format('YYYYMMDDHHmmss');
			this.defaultFileName =
				clientInfo['client_name'] +
				'様_' +
				projectInfo['project_name'] +
				'_請求書_' +
				downloadDateTime +
				'.pdf';
		},

		/**
		 * Get quotation contents from estimate_particular and estimate_details
		 * @param {String} projectId
		 */
		async _getEstimateParticular(projectId) {
			console.log('GET PARTICULAR INFO');
			let response = await EstimateParticularService.get(projectId);
			// console.log(response);
			if (response && response.status === 200) {
				// let estimateParticular = response.data['estimate_particular'];
				let estimateDetails = response.data['estimate_details'];
				this.estimateParticular = response.data['estimate_particular'];
				this.quoteId = response.data['estimate_particular']['id'];
				console.log('!!!!!!!!!!!!!!!!!!!!!!!!', this.quoteId);
				if (estimateDetails.length > 0) {
					this.isSaveButtonClicked = true;
				}
			} else {
				// TODO: Error notification
				console.log('Update confirm flag failed');
			}
		},

		async _uploadPdf(params) {
			try {
				let response = await TransferService.postPdf(params);
				if (!response || response.status !== 200) {
					throw 'Upload PDF failed!';
				}

				console.log('%c Upload PDF successfully!', 'color: red');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},

		async _updateInvoice(quoteId, content) {
			try {
				let response = await InvoiceService.update(quoteId, content);
				if (!response || response.status !== 200) {
					throw 'Update invoie failed';
				}

				console.log('%c Update invoie successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},

		async _updateDocument(projectId, content) {
			console.log({ content });
			try {
				let response = await DocumentService.update(projectId, content);
				if (response && response.status !== 200) {
					throw 'Get add cost detail failed';
				}
				console.log('%c Update document successfully', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		// async _updateProject(projectId, content) {
		// 	try {
		// 		let response = await ProjectService.update(projectId, content);
		// 		if (!response || response.status !== 200) {
		// 			throw 'Upload Project failed!';
		// 		}
		// 		console.log('%c Upload Project successfully!', 'color: green');
		// 	} catch (error) {
		// 		console.log(`%c ${error}`, 'color: red');
		// 	}
		// },

		/**
		 * Update Estimate particular
		 * @param {Integer} estimateParticularId
		 * @param {Object} body update object
		 */
		async _updateEstimateParticular(estimateParticularId, body) {
			try {
				let response = await EstimateParticularService.update(estimateParticularId, {
					doc_content: body,
				});
				if (response && response.status === 200) {
					console.log('%c Update Estimate particular successfully!', 'color: green');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		_checkStatus() {
			if (this.settlementStatus === 'onSave') {
				this.showButton = true;
				this.showEditButton = true;
				this.showDownloadButton = true;
			} else if (this.settlementStatus === 'onWaitingPayment') {
				this.showButton = false;
				this.showEditButton = false;
				this.showDownloadButton = true;
			}
		},
		/**
		 * Get Invoice Data
		 * @param {Integer} quoteId EstimateParticular ID
		 * @returns invoiceData
		 */
		async _getInvoice(quoteId) {
			try {
				let response = await InvoiceService.get(quoteId);
				if (!response || response.status !== 200) {
					throw 'Get Invoice failed!';
				}
				console.log('%c Get Invoice successfully!', 'color: red');
				let invoiceData = response.data;
				this.invoiceData = invoiceData;
				this.pdfFileKey = invoiceData['pdf_file_key'];
				return invoiceData;
			} catch (error) {
				console.log(error);
			}
		},
	},

	async created() {
		socketClient.listen('new_settlement_client_confirm', () => {
			this._disableElements();
		});

		socketClient.listen('new_settlement_quotation_content', async (data) => {
			this.$store.commit('setIsAppProcessing', true);

			console.log(data);
			await this._getQuotationContents(this.projectId);

			this.$store.commit('setIsAppProcessing', false);
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		moment.locale('ja');
		await this._getEstimateParticular(this.projectId);

		this.$store.commit('setIsAppProcessing', false);
	},

	updated() {
		this._checkStatus();
	},

	beforeDestroy() {
		socketClient.removeAllListeners('new_settlement_client_confirm');

		socketClient.removeAllListeners('new_settlement_quotation_content');
	},
};
