import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import FormInput from '@/components/FormInput/FormInput.vue';
import DetailTable from '@/components/DetailTable/DetailTable.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import ZipAddressService from '@/services/API/zip_address.service';
import EstimateParticularService from '@/services/API/estimate_particular.service';
import EstimateDetailService from '@/services/API/estimate_detail.service';
import InvoiceService from '@/services/API/invoice.service';
import ProjectService from '@/services/API/project.service';
import socketClient from '@/services/SOCKET';
import { mapState } from 'vuex';
import autoBankMixin from '@/mixin/autoBankMixin';

const SUB_TOTAL_INDEX = 0;
const TOTAL_TAX_INDEX = 1;
const HOLDING_TAX_INDEX = 2;
const TOTAL_INDEX = 3;
const SUPPLIER_NAME_INDEX = 3;
const COMPANY_NAME_INDEX = 6;
const ESTIMATOR_NAME_INDEX = 7;
const POSTAL_CODE_INDEX = 8;
const ADDRESS_INDEX = 9;
const PHONE_NUMBER_INDEX = 10;
const MAX_ITEMS = 100;
const LIMITED_AMOUNT = 99990000;

export default {
	components: {
		ScreenHeader,
		FormInput,
		DetailTable,
		SaveButtonArea,
		NotificationModal,
	},
	mixins: [autoBankMixin],
	props: {
		/**
		 * @binding estimate Props Data
		 * @value estimateParticular {object}: estimate particular data
		 * @value estimateDetails {array}: array of estimate detail objects
		 */
		estimatePropData: {
			type: Object,
		},
		propUserId: {
			type: Number,
		},
		projectId: {
			type: Number,
		},
		invoiceData: {
			type: Object,
		},
		bankInfo: {
			type: Object,
		},
	},
	data() {
		return {
			middleContent: ' 様 ／ ',
			screenIcon: 'addcost-icon',
			screenName: '請求書作成',
			projectDeadline: '2021 年 9 月 25日',
			formInputs: [
				{
					inputLabel: '請求先',
					type: 'text',
					hiddenInput: true,
					toggleBorder: true,
					keyLabel: '',
				},
				{
					inputLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.issueDate.label'
					),
					noteLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.issueDate.note-label'
					),
					keyLabel: 'issue_date',
					type: 'date',
					toggleStyle: true,
					inputPropContent: '',
				},
				{
					inputLabel: '振込期限',
					noteLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.issueDate.note-label'
					),
					keyLabel: 'expiry_date',
					type: 'date',
					toggleStyle: true,
					inputPropContent: '',
					modalCustom: true,
				},
				{
					inputLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.supplier.label'
					),
					placeholder: this.$t(
						'creatorApp.estimateScreen.quotationScreen.supplier.placeholder'
					),
					keyLabel: 'supplier_name',
					type: 'text',
					inputPropContent: '',
					disabled: false,
				},
				{
					inputLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.projectTitle.label'
					),
					placeholder: this.$t(
						'creatorApp.estimateScreen.quotationScreen.projectTitle.placeholder'
					),
					keyLabel: 'project_name',
					type: 'text',
					inputPropContent: '',
					disabled: false,
				},
				{
					inputLabel: '請求元',
					keyLabel: '',
					type: 'text',
					hiddenInput: true,
					toggleBorder: true,
				},
				{
					inputLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.companyName.label'
					),
					placeholder: this.$t(
						'creatorApp.estimateScreen.quotationScreen.companyName.placeholder'
					),
					keyLabel: 'company_name',
					type: 'text',
					inputPropContent: '',
					disabled: false,
				},
				{
					inputLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.fullName.label'
					),
					placeholder: this.$t(
						'creatorApp.estimateScreen.quotationScreen.fullName.placeholder'
					),
					keyLabel: 'estimator_name',
					type: 'text',
					inputPropContent: '',
					disabled: false,
				},
				{
					inputLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.postalCode.label'
					),
					noteLabel: '',
					keyLabel: 'postal_code',
					placeholder: this.$t(
						'creatorApp.estimateScreen.quotationScreen.postalCode.placeholder'
					),
					type: 'text',
					inputPropContent: '',
					disabled: false,
				},
				{
					inputLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.address.label'
					),
					placeholder: this.$t(
						'creatorApp.estimateScreen.quotationScreen.address.placeholder'
					),
					keyLabel: 'address',
					type: 'text',
					inputPropContent: '',
					disabled: false,
				},
				{
					inputLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.phoneNumber.label'
					),
					keyLabel: 'phone_number',
					placeholder: this.$t(
						'creatorApp.estimateScreen.quotationScreen.phoneNumber.placeholder'
					),
					type: 'number',
					inputPropContent: '',
					isShowBadge: true,
					inputRequired: true,
					disabled: false,
				},
				{
					inputLabel: '口座情報',
					keyLabel: '',
					type: 'text',
					hiddenInput: true,
					toggleBorder: true,
				},
				{
					inputLabel: '銀行名',
					keyLabel: 'bank_name',
					placeholder: '銀行名',
					caption: '銀行',
					type: 'text',
					inputPropContent: '',
					disabled: false,
					isShowBadge: true,
					inputRequired: true,
				},
				{
					inputLabel: '支店名',
					keyLabel: 'bank_branch',
					placeholder: '支店名',
					caption: '支店',
					type: 'text',
					inputPropContent: '',
					disabled: false,
					isShowBadge: true,
					inputRequired: true,
				},
				{
					inputLabel: '口座種別',
					keyLabel: 'account_type',
					placeholder: this.$t(
						'creatorApp.estimateScreen.quotationScreen.phoneNumber.placeholder'
					),
					type: 'radio',
					checkBoxOptions: ['普通', '当座'],
					inputPropContent: '普通',
					disabled: false,
					isShowBadge: true,
					inputRequired: true,
				},
				{
					inputLabel: '口座名義人',
					keyLabel: 'account_holder',
					placeholder: '口座名義人',
					type: 'text',
					inputPropContent: '',
					disabled: false,
					isShowBadge: true,
					inputRequired: true,
				},
				{
					inputLabel: '口座番号',
					keyLabel: 'account_number',
					placeholder: '口座番号',
					type: 'number',
					inputPropContent: '',
					disabled: false,
					isShowBadge: true,
					inputRequired: true,
				},
				{
					inputLabel: this.$t('creatorApp.estimateScreen.quotationScreen.detail.label'),
					type: '',
					hiddenInput: true,
					keyLabel: '',
					disabled: true,
				},
			],
			billCosts: [
				{
					label: this.$t('creatorApp.estimateScreen.quotationScreen.subTotal.label'),
					keyLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.subTotal.key-label'
					),
					moneyAmount: '0,000',
					moneyUnit: this.$t('creatorApp.estimateScreen.quotationScreen.moneyUnit'),
				},
				{
					label: this.$t('creatorApp.estimateScreen.quotationScreen.tax.label'),
					keyLabel: this.$t('creatorApp.estimateScreen.quotationScreen.tax.key-label'),
					moneyAmount: '0,000',
					moneyUnit: this.$t('creatorApp.estimateScreen.quotationScreen.moneyUnit'),
				},
				{
					label: this.$t('creatorApp.estimateScreen.quotationScreen.holding_tax.label'),
					moneyAmount: '0,000',
					moneyUnit: this.$t('creatorApp.estimateScreen.quotationScreen.moneyUnit'),
				},
				{
					label: this.$t('creatorApp.estimateScreen.quotationScreen.total.label'),
					keyLabel: this.$t(
						'creatorApp.estimateScreen.quotationScreen.total.key-label'
					),
					moneyAmount: '0,000',
					moneyUnit: this.$t('creatorApp.estimateScreen.quotationScreen.moneyUnit'),
				},
			],
			// 詳細データ
			infoFormInput: {},
			// 明細データ
			estimateDetails: [],
			originEstimateDetails: [],
			comparesionEstimateDetails: [],
			// 明細データからトータルになる金額のデータ
			estimateParticular: {},
			estimateParticularId: undefined,
			infoBillCosts: {},
			remarkContent: '',
			deletedItems: [],
			canSaveQuotationContents: false,
			formInputValid: false,
			detailTableValid: true,
		};
	},
	computed: {
		// Get userId from store
		...mapState([
			'projectId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'userId',
			'schedule',
		]),
	},
	watch: {
		/**
		 * Watch prop estimatePropData change
		 * @param {object} estimatePropData
		 */
		estimatePropData: {
			handler: function (estimatePropData) {
				this.originEstimateDetails = [...estimatePropData.estimateDetails];
				this.comparesionEstimateDetails = [...estimatePropData.estimateDetails];
				this.estimateParticular = {
					...estimatePropData.estimateParticular,
				};
				this.remarkContent = estimatePropData.estimateParticular['comment'];
				if (estimatePropData.estimateParticular['id']) {
					this.estimateParticularId = estimatePropData.estimateParticular['id'];
					this.retryReasonContent = estimatePropData.estimateParticular['retry_reason'];
				}
			},
			deep: true,
		},

		/**
		 * Watch estimateParticular
		 * get inputPropContent properly of formInputs
		 * @param {object} estimateParticular
		 */
		estimateParticular: {
			handler: function (estimateParticular) {
				this.formInputs.map((item) => {
					item.inputPropContent = estimateParticular[`${item.keyLabel}`];
				});
			},
			deep: true,
		},

		clientInfo: {
			handler(newClientInfo) {
				if (newClientInfo?.client_name) this.syncEstimateDataFromClient();
			},
		},
		creatorInfo: {
			handler(newCreatorInfo) {
				if (newCreatorInfo?.id) {
					this.syncEstimateDataFromCreator();
				}
			},
		},
	},
	methods: {
		onClickInput() {},
		dataCalculate(value) {
			let subTotal = value.subTotal;
			let totalTax = value.totalTax;
			let holdingTax = value.holdingTax;
			let total = subTotal + totalTax;
			if (this.projectInfo.with_holding_tax == 1) {
				total = total + holdingTax;
			}
			this.billCosts[TOTAL_TAX_INDEX].moneyAmount = totalTax;
			this.infoBillCosts['subtotal'] = subTotal;
			this.infoBillCosts['consumption_tax'] = totalTax;
			this.infoBillCosts['holding_tax'] = holdingTax;
			if (!value.validAmount) {
				this.$bvModal.show(this.plainModalId);
				this.isSaveButtonDisable();
			} else {
				this.infoBillCosts['total'] = total;
				this.billCosts[TOTAL_INDEX].moneyAmount = total;
				this.billCosts[HOLDING_TAX_INDEX].moneyAmount = holdingTax;
				this.billCosts[SUB_TOTAL_INDEX].moneyAmount = subTotal;
			}
		},
		syncEstimateDataFromCreator() {
			this.formInputs[COMPANY_NAME_INDEX].initContent =
				this.creatorInfo['company_name'];
			this.formInputs[ESTIMATOR_NAME_INDEX].initContent =
				this.creatorInfo['register_name'];
			this.formInputs[POSTAL_CODE_INDEX].initContent = this.creatorInfo['postal_code'];
			this.formInputs[ADDRESS_INDEX].initContent = this.creatorInfo['address'];
			this.formInputs[PHONE_NUMBER_INDEX].initContent =
				this.creatorInfo['phone_number'];
		},

		syncEstimateDataFromClient() {
			this.formInputs[SUPPLIER_NAME_INDEX].initContent = this.clientInfo['client_name'];
		},
		_checkPostalCode(postalCode) {
			const regex = /^\d{3}-\d{4}$/;

			// Valid postalCode
			if (regex.test(postalCode)) return true;

			return false;
		},
		async _getAddress(zipCode) {
			try {
				let response = await ZipAddressService.get(zipCode);
				if (!response || response.status !== 200) {
					throw `Get Address failed`;
				}

				// Check zipcode is not valid or not match any address
				// Check response.data.code
				if (!response || (response.data.code && response.data.code !== 200)) {
					throw `Get Address failed: ${response.data.message}`;
				}

				console.log('%c Get Address successfully', 'color: green');
				return response.data.fullAddress;
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
				return;
			}
		},
		async getFormInputData(transferredObject) {
			// Auto fill address if enter postal code
			// this.isAutoFillAddress using for prevent auto fill postalCode when just loading page
			if (
				this.isAutoFillAddress &&
				transferredObject.key === 'postal_code' &&
				this._checkPostalCode(transferredObject.val)
			) {
				let companyAddress = await this._getAddress(transferredObject.val);
				this.formInputs[ADDRESS_INDEX].inputPropContent = companyAddress;
				this.formInputs[ADDRESS_INDEX].initContent = '';
			}
			this.infoFormInput[transferredObject.key] = transferredObject.val;

			// Validate inputs
			if (
				!this.infoFormInput.supplier_name ||
				!this.infoFormInput.issue_date ||
				!this.infoFormInput.expiry_date ||
				!this.infoFormInput.project_name ||
				!this.infoFormInput.company_name ||
				!this.infoFormInput.phone_number ||
				!this.infoFormInput.estimator_name ||
				!this.infoFormInput.address ||
				!this.infoFormInput.postal_code
			) {
				this.formInputValid = false;
			} else {
				this.formInputValid = true;
			}
		},

		isSaveButtonDisable() {
			if (this.formInputValid && this.detailTableValid) {
				if (this.infoBillCosts['subtotal'] > LIMITED_AMOUNT) {
					return true;
				}
				return false; // If all required input is filled, return false to enable Save Button
			} else {
				return true; // If all required input is not fill yet, return true to disable Save Button
			}
		},

		async onSaveButtonClick() {
			this.$store.commit('setIsAppProcessing', true);

			if (this.infoFormInput.project_name != this.projectInfo.project_name) {
				await this._updateProjectName();
			}
			// Get remark content data

			let remarkContent = {};
			// Check v-model remarkContent if empty or not.
			// If empty, return "<blank_space>" for generate PDF
			if (this.remarkContent === '') {
				remarkContent = {
					comment: ' ',
				};
			} else {
				remarkContent = {
					comment: this.remarkContent,
				};
			}

			let userId = {
				update_user: this.userId,
			};
			// Merge data from intoFormInput, infoBillCost and remarkContent into estimateParticular
			const estimateParticular = {
				...this.infoFormInput,
				...this.infoBillCosts,
				...remarkContent,
				...userId,
			};

			this.estimateParticular = estimateParticular;
			// Implement save data to database
			await this._saveEstimateData();
			await this._saveInvoiceData();

			//Check if quotation have been created or not
			if (this.canSaveQuotationContents) {
				let sendData = {
					isShowQuotation: true,
				};
				this.$emit('on-save-btn-click', sendData);
			} else {
				// Remove error modal temporary
				console.log('%c Save quotation failed!', 'color: red');
			}
		},
		onClose() {
			this.$emit('on-close-settlement-screen');
		},
		async _saveEstimateData() {
			try {
				if (this.estimateParticularId && this.estimateStatus === 'onDecline') {
					await this._createNewEstimateParticularVersion();
					this.canSaveQuotationContents = true;
					return;
				}

				// estimateParticularId does not exist, create new estimateParticular
				if (!this.estimateParticularId) {
					let estimateParticular = {
						...this.estimateParticular,
					};
					// Add project_id
					estimateParticular['project_id'] = this.projectId;
					estimateParticular['update_user'] = this.userId;
					estimateParticular['status'] = 'onSave';

					await this._createNewEstimateParticular(estimateParticular);
				} else {
					await this._updateEstimateParticular(
						this.estimateParticularId,
						this.estimateParticular
					);
				}
				console.log('___________________________________', this.estimateDetails);
				//estimateParticularId is existed
				if (this.estimateDetails.length > 0) {
					this.isEstimateDetailsCreated = true;
					await this._implementCreateUpdateAndDelete(this.estimateDetails);
				}
				this.canSaveQuotationContents = true;
			} catch (error) {
				this.canSaveQuotationContents = false;
				console.log({ QUOTATION_ERROR: error });
			}
		},

		async _createNewEstimateParticularVersion() {
			let estimateParticular = {
				...this.estimateParticular,
			};

			// Delete id
			delete estimateParticular.id;
			delete estimateParticular.date_created;
			delete estimateParticular.date_modified;

			// Add value
			estimateParticular['project_id'] = this.projectId;
			estimateParticular['update_user'] = this.userId;
			estimateParticular['retry_reason'] = this.retryReasonContent;
			estimateParticular['status'] = 'onSave';
			estimateParticular['confirm_time'] = null;

			await this._createNewEstimateParticular(estimateParticular);

			// Remove estimate details id
			let estimateDetails = [...this.estimateDetails];
			estimateDetails.map((estimateDetail) => {
				delete estimateDetail.id;
				delete estimateDetail.date_created;
				delete estimateDetail.date_modified;
			});
			await this._createBulkEstimateDetails(estimateDetails, this.estimateParticularId);
		},
		async _createNewEstimateParticular(estimateParticular) {
			let response = await EstimateParticularService.post({
				doc_content: estimateParticular,
			});

			if (response && response.status === 200) {
				let estimateParticularId = response.data.id;
				this.estimateParticularId = estimateParticularId;
				console.log('Create Estimate particular successfully!');
			} else {
				throw response.message;
			}
		},
		getDetailTableInputStatus(data) {
			this.detailTableValid = data.isValid;
			if (data.length >= MAX_ITEMS) {
				this.showAddButton = false;
			} else {
				this.showAddButton = true;
			}
		},
		getEstimateDetail(value) {
			this.estimateDetails = value;
			this.$emit('on-estimate-details-change', value);
		},
		getDeletedItems(deletedItems) {
			this.deletedItems = deletedItems;
		},
		async _createBulkEstimateDetails(estimateDetails, quoteId) {
			let bodyContents = [...estimateDetails];

			bodyContents.map((bodyContent) => {
				bodyContent['quote_id'] = quoteId;
				bodyContent['update_user'] = this.userId;
			});
			let response = await EstimateDetailService.postBulk({
				doc_content: bodyContents,
			});
			if (response && response.status === 200) {
				console.log('Create Bulk Estimate details successfully!');
			} else {
				throw response.message;
			}
		},
		async _implementCreateUpdateAndDelete(estimateDetails) {
			let classificationResult = this._classifyCreateAndUpdateItems(estimateDetails);
			let createdItems = classificationResult.createdItems;
			let updatedItems = classificationResult.updatedItems;
			let deletedItems = this.deletedItems;

			if (createdItems && createdItems.length > 0) {
				await this._createBulkEstimateDetails(createdItems, this.estimateParticularId);
			}
			if (updatedItems && updatedItems.length > 0) {
				await this._updateBulkEstimateDetails(updatedItems);
			}

			if (deletedItems && deletedItems.length > 0) {
				await this._deleteBulkEstimateDetails(deletedItems);
			}
		},
		_getChangedItems(oldArray, newArray) {
			let changedItems = newArray.filter(
				(elm) =>
					!oldArray.map((elm) => JSON.stringify(elm)).includes(JSON.stringify(elm))
			);
			return changedItems;
		},
		_classifyCreateAndUpdateItems(estimateDetails) {
			let changedItems = this._getChangedItems(
				this.comparesionEstimateDetails,
				estimateDetails
			);

			let updatedItems = [];
			let createdItems = [];

			changedItems.map((changedItem) => {
				changedItem['id']
					? updatedItems.push(changedItem)
					: createdItems.push(changedItem);
			});
			let result = {
				updatedItems: updatedItems,
				createdItems: createdItems,
			};

			// console.log('classify result', result);
			return result;
		},
		async _updateBulkEstimateDetails(estimateDetails) {
			let response = await EstimateDetailService.updateBulk({
				doc_content: estimateDetails,
			});
			if (response && response.status === 200) {
				console.log('Update Bulk Estimate details successfully!');
			} else {
				throw response.message;
			}
		},
		async _deleteBulkEstimateDetails(estimateDetailIds) {
			let response = await EstimateDetailService.deleteBulk({
				doc_content: estimateDetailIds,
			});
			if (response && response.status === 200) {
				console.log('Delete Bulk Estimate details successfully!');
			} else {
				throw response.message;
			}
		},
		async _updateProjectName() {
			this.projectInfo.project_name = this.infoFormInput.project_name;
			this.$store.commit('setProjectInfo', this.projectInfo);
			await ProjectService.update(this.projectId, {
				project_name: this.infoFormInput.project_name,
			});
			socketClient.send('data_transfer', {
				data: {
					event_name: 'admin_update_project',
					content: {
						updateDecisionContent: true,
					},
				},
				room: this.projectId,
			});
		},
		async _saveInvoiceData() {
			try {
				if (!this.invoiceData.id) {
					this._createNewInvoice(this.estimateParticular);
					this.$emit('on-change-settlement-status', 'onSave');
				} else {
					this._updateInvoice(this.estimateParticular);
				}

				//estimateParticularId is existed
				//Check create, update, delete estimate detail here
				if (this.estimateDetails.length > 0) {
					this.isEstimateDetailsCreated = true;
					// Update EstimateParticular after because subtotal, total, and tax change
					await this._updateEstimateParticular(
						this.estimateParticularId,
						this.estimateParticular
					);
				}
				this.canSaveQuotationContents = true;
			} catch (error) {
				this.canSaveQuotationContents = false;
			}
		},

		async _updateEstimateParticular(estimateParticularId, estimateParticular) {
			let body = { ...estimateParticular };
			body['update_user'] = this.userId;

			delete body['project_name'];
			delete body['issue_date'];
			delete body['expiry_date'];
			delete body['account_type'];
			delete body['account_holder'];
			delete body['account_number'];
			delete body['bank_branch'];
			delete body['bank_name'];
			let response = await EstimateParticularService.update(estimateParticularId, {
				doc_content: body,
			});
			if (response && response.status === 200) {
				console.log('Update Estimate particular successfully!');
			} else {
				throw response.message;
			}
		},

		/**
		 * Update Invoice
		 * @param {Object} estimateParticular estimateParticular Object
		 */
		async _updateInvoice(estimateParticular) {
			let invoiceData = {
				issue_date: estimateParticular['issue_date'],
				transfers_deadline: estimateParticular['expiry_date'],
				bank_name: estimateParticular['bank_name'],
				bank_branch: estimateParticular['bank_branch'],
				account_type: estimateParticular['account_type'],
				account_number: estimateParticular['account_number'],
				account_holder: estimateParticular['account_holder'],
				update_user: this.userId,
			};

			let response = await InvoiceService.update(
				this.estimateParticularId,
				invoiceData
			);

			if (response || response.status !== 200) {
				console.log('%c Update Invoice successfully', 'color: red');
			} else {
				throw response.message;
			}
		},

		/**
		 * Create New Invoice
		 * @param {Object} estimateParticular estimateParticular Object
		 */
		async _createNewInvoice(estimateParticular) {
			let invoiceData = {
				quote_id: this.estimateParticularId,
				issue_date: estimateParticular['issue_date'],
				transfers_deadline: estimateParticular['expiry_date'],
				bank_name: estimateParticular['bank_name'],
				bank_branch: estimateParticular['bank_branch'],
				account_type: estimateParticular['account_type'],
				account_number: estimateParticular['account_number'],
				account_holder: estimateParticular['account_holder'],
				update_user: this.userId,
				pdf_invoice_image_key: this.invoiceData.pdf_image_key,
				pdf_invoice_file_key: this.invoiceData.pdf_file_key,
				status: 'onSave',
			};

			let response = await InvoiceService.post({
				doc_content: invoiceData,
			});

			if (response || response.status !== 200) {
				console.log('%c Create Invoice successfully', 'color: red');
			} else {
				throw response.message;
			}
		},
	},
	mounted() {
		// Update when load estimate by url
		// Because clientInfo and creatorInfo are geted from previous screen
		if (this.clientInfo) {
			this.syncEstimateDataFromClient();
		}
		if (this.creatorInfo) {
			this.syncEstimateDataFromCreator();
		}
	},
};
