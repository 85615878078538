import { render, staticRenderFns } from "./SettlementQuotationScreen.vue?vue&type=template&id=68504a16&scoped=true&"
import script from "./SettlementQuotationScreen.js?vue&type=script&lang=js&"
export * from "./SettlementQuotationScreen.js?vue&type=script&lang=js&"
import style0 from "./SettlementScreen.scss?vue&type=style&index=0&id=68504a16&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68504a16",
  null
  
)

export default component.exports