import AutoBankService from '@/services/API/autoBank.service';
const BANK_NAME_INDEX = 12;
const BRANCH_NAME_INDEX = 13;



export default {
	data() {
		return {
			bankCode: undefined,
			autoBanks: [],
			autoBranches: [],
			// debounce Bank
			timerBank: 300,
			timeOutBank: null,
			counterBank: 0,

			// debounce Branch
			timerBranch: 300,
			timeOutBranch: null,
			counterBranch: 0,
		};
	},
	watch: {
		async 'infoFormInput.bank_name'(newBankName, oldBankName) {
			if (oldBankName === undefined) return;

			this.formInputs[BANK_NAME_INDEX].inputPropContent = newBankName;
			this.counterBank += 1;
			clearTimeout(this.timeOutBank);

			this.timeOutBank = setTimeout(async () => {
				await this.getBankName(newBankName);
			}, this.timerBank);
		},

		async 'infoFormInput.bank_branch'(newbranchName, oldBranchName) {
			if (oldBranchName === undefined) return;

			this.counterBranch += 1;
			clearTimeout(this.timeOutBranch);

			console.log({ newbranchName, thisbankCode: this.bankCode });

			this.timeOutBranch = setTimeout(async () => {
				await this.getBranchName(newbranchName, this.bankCode);
			}, this.timerBranch);
		},
	},
	methods: {
		onSelectOption(item) {
			if (item?.bank_code) {
				this.infoFormInput.bank_branch = item?.name_kanji;
				this.formInputs[BRANCH_NAME_INDEX].inputPropContent = item?.name_kanji;
			} else {

				this.bankCode = item?.code;
				this.infoFormInput.bank_name = item?.name_kanji;
				this.formInputs[BANK_NAME_INDEX].inputPropContent = item?.name_kanji;

				this.infoFormInput.bank_branch = '';
				this.formInputs[BRANCH_NAME_INDEX].inputPropContent = '';
			}
		},

		async onBranchEmpty(value) {
			await this.getBranchName(value, this.bankCode);
			this.infoFormInput.bank_name = value;
		},

		async onBankEmpty(value) {
			this.infoFormInput.bank_branch = value;
		},

		/**
		 * Get bankName by letters
		 * @param {String} letters letters of bankName
		 * @returns {Array} list of bankName objects
		 */
		async getBankName(letters) {
			try {
				const response = await AutoBankService.getBankName(letters);

				if (!response) {
					throw Error('Get bankName error');
				}

				const banks = response.data;
				console.log({ banks });

				this.autoBanks = banks ? banks : [];
			} catch (error) {
				console.log(error);
			}
		},
		/**
		 * Get branchName by letters
		 * @param {String} letters letters of branchName
		 * @returns {Array} list of branchName objects
		 */
		async getBranchName(letters, bankCode) {
			try {
				if (this.infoFormInput.bank_name == '') {
					this.autoBranches = [];
					return;
				}
				const response = await AutoBankService.getBranchName(letters, bankCode);

				if (!response) {
					throw Error('Get bankName error');
				}

				const branchs = response.data;
				console.log({ branchs });
				this.autoBranches = branchs ? branchs : [];
			} catch (error) {
				console.log(error);
			}
		},
	},
	mounted() {
		setTimeout(async () => {
			if (!this.bankCode && this.infoFormInput.bank_name) {
				const response = await AutoBankService.getBankName(
					this.infoFormInput.bank_name
				);

				if (!response) {
					throw Error('Get bankName error');
				}

				const banks = response.data;
				this.onSelectOption(banks[0]);
			}
		});
	},
};
